.accordion-button {
  color: #ffcb37;
  background: none;
}

.nav-link-tab {
  display: block;
  color: #2a2a2c;
  background: none;
  border: 0;

  &:hover,
  &:focus {
    color: #2a2a2c;
  }

  &.disabled {
    color: #2a2a2c;
    pointer-events: none;
    cursor: default;
  }
}
.row {
  justify-content: center;
}


/* General Table Styling */
.table {
  width: 100%; /* Full width */
  background-color: #fdfdfd; /* Dark theme background */
  color: var(--text-light); /* Light text for contrast */
  border-collapse: collapse; /* Ensures borders between cells are merged */
  margin-bottom: 1rem; /* Spacing below the table */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Hides anything outside the border radius */
}

/* Table Headers */
.table th {
  background-color: #2a2a2c; /* Gold color for headers for emphasis */
  color: #ffcb37; /* Dark text for readability */
  padding: 12px 15px; /* Comfortable padding around text */
  text-align: left; /* Align text to the left */
  font-weight: bold; /* Make header fonts bold */
}

/* Table Cells */
.table td {
  background-color: #ffffff; /* Slightly lighter than table background */
  padding: 10px 15px; /* Padding within cells */
  border-top: 1px solid #444; /* Subtle border for separation */
}

/* Hover effect for rows */
.table tbody tr:hover {
  background-color: #2a2a2c; /* Slightly lighter on hover for interactivity */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto; /* Allows table to scroll horizontally on small devices */
    -webkit-overflow-scrolling: touch;
  }
}












.modal-content {
  background: #cfcfcf;
  color: var(--text-light);
  border-radius: 10px;
}

.modal-header, .modal-footer {
  background: #adadad;
  color: #000000;
  padding: 10px 15px;
}

.modal-body {
  background-color: #333;
  padding: 20px;
}

.modal-button {
  background-color: var(--emerald-green);
  color: var(--text-dark);
}

.modal-button:hover {
  background-color: #ffcb37;
}
@media (max-width: 768px) {
  .lottery-card, .other-card-class {
    width: 100%;
    margin: 0 auto;
  }

  .page-layout__content {
    align-items: center;
    text-align: center;
  }
}

a, button {
  color: #ffcb37;
  transition: background-color 0.3s;
}

a:hover, button:hover {
  background-color: #6d6347; /* Adjust as needed */
}
















/* Modal base styling */
.modal-content {
  background: #2a2a2c; /* Dark background for the modal content */
  color: var(--text-light);
  border-radius: 10px; /* Rounded corners for the modal */
  border: none; /* Remove default borders */
}

/* Modal header styling */
.modal-header {
  /* background: #1e2a35; */
  background: #8b8b8b; /* Darker shade for the header to differentiate from body */
  color: #ffffff; /* Gold color for headings */
  border-bottom: 1px solid var(--silver); /* Subtle separation from the modal body */
  border-top-left-radius: 10px; /* Continue rounded corners from modal */
  border-top-right-radius: 10px;
}

/* Modal body styling */
.modal-body {
  background-color: #333; /* Slightly lighter than the header for contrast */
  padding: 20px; /* Ample padding for content */
}

/* Modal footer styling */
.modal-footer {
  background: #28282a; /* Consistent with header but optional */
  border-top: 1px solid var(--silver); /* Define separation from the body */
  padding: 10px 15px; /* Consistent padding */
}

/* Close button specific styling */
.modal-header .close {
  color: #ffcb37;
  text-shadow: none; /* Remove default text shadow */
}

/* Custom buttons inside modal */
.modal-button {
  background-color: var(--emerald-green); /* Thematic button color */
  color: var(--text-dark);
  border: none; /* Sleek, borderless buttons */
}

.modal-button:hover {
  background-color: #ffcb37; /* Change on hover for interactivity */
}

















/* Override toastify styles */
.Toastify__toast--success {
  background-color: #ffcb37 !important;
  color: var(--text-dark) !important;
}

.Toastify__toast--error {
  background-color: #ffcb37 !important;
  color: var(--dark-blue) !important;
}

.Toastify__toast--info {
  background-color: #ffcb37 !important;
  color: var(--text-dark) !important;
}

.Toastify__toast--warning {
  background-color: #ffcb37 !important;
  color: var(--text-dark) !important;
}

.Toastify__toast-body {
  font-family: 'Poppins', sans-serif;
}

.Toastify__close-button {
  color: var(--text-dark) !important;
}











/* Base Colors */
:root {
  --dark-blue: #243B55;
  --gold: #ffcb37;
  --emerald-green: #50C878;
  --silver: #C0C0C0;
  --text-light: #FFFFFF;
  --text-dark: #000000;
}

.navbar {
  background-color: #35363b;
  color: var(--text-light);
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: var(--dark-blue); 

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  color: var(--text-light);
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3 {
  color: #ffcb37;
}

a, button {
  /*background-color: var(--text-dark);*/
  color: #ffcb37;
  transition: background-color 0.3s;
}

a:hover, button:hover {
  background-color: #6d6347;
}

/* Layout */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  background: #2a2a2c;
  border-radius: 10px;
  padding: 15px;
}

/* Typography */
h1 {
  font-size: 2.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.6;
}

















/* Styling for the overall card */
.lottery-card {
  /*width: 25rem;  Fixed width for all cards */
  border-radius: 18px; /* Rounded corners */
  box-shadow: 0 8px 16px rgba(253, 253, 253, 0.6); /* More pronounced shadow */
  background: linear-gradient(to bottom, #333, #1c1c1e 100%); /* Gradient background */
  color: white; /* Light text for contrast */
  overflow: hidden; /* Ensures contained elements like dropdowns do not overflow */
  transition: transform 0.3s ease-in-out; /* Smooth transform effect */
  display: flex;
  justify-content: center;
  background: #0eabf8;
  overflow: hidden;

  width: 100%; /* Adjust width to 100% for smaller screens */
  max-width: 25rem; /* Max width remains 25rem */
  margin: 10px auto; /* Add vertical margin and center align */
}
@media (min-width: 576px) { /* Adjust breakpoint as necessary */
  .lottery-card {
    width: calc(50% - 20px); /* Two cards per row with some spacing */
    margin: 10px; /* Consistent spacing around cards */
  }
}

@media (min-width: 768px) {
  .lottery-card {
    width: calc(33.333% - 20px); /* Three cards per row for larger screens */
  }
}
.lottery-card:hover {
  transform: translateY(-5px); /* Slight raise effect on hover */
}

/* Header styling */
.lottery-card .card-header {
  /*background-color: rgba(162, 162, 180, 0.85);  */
  background-color: #172c41;
  padding: 10px 15px; /* Spacing around content */
  border-top-left-radius: 18px; /* Rounded top corners */
  border-top-right-radius: 18px;
  padding: 10px 15px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  color: #ffffff;
}

/* Body styling */
.lottery-card .card-body {
  background-color: #2a2a2c; /* Dark background for body */
  padding: 20px; /* More padding for content */
}

/* Title styling with gradient text */
.lottery-card .card-title {
  background: linear-gradient(
    to right,
    rgb(192, 202, 51),  /* olive green, reminiscent of currency */
    rgb(212, 175, 55),  /* golden rod for a rich gold feel */
    rgb(184, 134, 11),  /* dark golden rod for deeper gold */
    rgb(153, 101, 21),  /* bronze for a touch of treasure */
    rgb(204, 164, 61),  /* softer gold for balance */
    rgb(189, 158, 57),  /* muted gold, keeps it warm */
    rgb(255, 215, 0)    /* bright gold for a highlight */
  );
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1.5em;
  text-align: center;
}
/* Title styling with gradient text */
.prize-pot {
  background: linear-gradient(
    to right,
    rgb(192, 202, 51),  /* olive green, reminiscent of currency */
    rgb(212, 175, 55),  /* golden rod for a rich gold feel */
    rgb(184, 134, 11),  /* dark golden rod for deeper gold */
    rgb(153, 101, 21),  /* bronze for a touch of treasure */
    rgb(204, 164, 61),  /* softer gold for balance */
    rgb(189, 158, 57),  /* muted gold, keeps it warm */
    rgb(255, 215, 0)    /* bright gold for a highlight */
  );
  
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1.5em;
  text-align: center;
}
.center {
  text-align: center;
}
/* Subtitle styling */
.lottery-card .card-subtitle {
  color: #ffcb37; /* Gold color for emphasis */
  font-size: 1.2em; /* Slightly larger font size */
}

/* Dropdown styling for a sleek appearance */
.dropdown-toggle {
  background: transparent; /* Transparent background for dropdown button */
  color: #000000; /* White text color */
  border: none; /* No border */
  padding: 0.5em 1em; /* Padding for better touch area */
}

/* Dropdown menu styling for better visibility */
.dropdown-menu {
  background-color: #ffcb37; /* Dark background for dropdown items */
  border: none; /* Removing default border */
}

.dropdown-item {
  color: #000000; /* Slightly lighter text for items */
  transition: background-color 0.2s; /* Smooth transition for background color */
}

.dropdown-item:hover {
  background-color: #000000; /* Dark grey background for hover */
  color: #ffcb37;
}

/* Styling for stats and other information with hover effect */
.lottery-card .stats {
  color: #4caf50; /* Green for stats, similar to success color */
  font-weight: bold; /* Bold for emphasis */
  transition: background-color 0.3s; /* Transition for hover effect */
}

.lottery-card .stats:hover {
  background-color: rgba(76, 175, 80, 0.2); /* Light hover background */
}

/* Countdown timer styling for urgency */
.countdown {
  color: #e91e63; /* Pink color for urgency */
  font-weight: bold; /* Bold for visibility */
  margin-top: 10px; /* Space from top */
}

.openBetsContainer {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.betRow:hover {
  cursor: pointer;
  background-color: #eaeaea;
}

.categorySelect {
  margin-bottom: 20px;
}

.betCardTitle {
  color: #333;
  font-weight: bold;
}

.claimButton {
  background-color: #4CAF50;
  color: white;
}

.claimButton:hover {
  background-color: #45a049;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2; /* Lighter shade for odd rows */
}

.table-hover tbody tr:hover {
  background-color: #ddd; /* Highlight on hover */
}

.statusIcon {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-size: contain;
}

.finalized {
  background-image: url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.onlygfx.com%2Fwp-content%2Fuploads%2F2018%2F04%2Fcompleted-stamp-3-1024x684.png&f=1&nofb=1&ipt=baeae82d3324228a6913f46a882c7eed7ce9321771824e79ae2d01235aaff77c&ipo=images'); /* Example icon */
}

.open {
  background-image: url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.onlygfx.com%2Fwp-content%2Fuploads%2F2018%2F04%2Fcompleted-stamp-3-1024x684.png&f=1&nofb=1&ipt=baeae82d3324228a6913f46a882c7eed7ce9321771824e79ae2d01235aaff77c&ipo=images'); 
}

.card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.button {
  border-radius: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .card {
      margin: 10px;
  }

  .table {
      display: block;
      overflow-x: auto;
  }
}
.openBetsContainer {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.openBetsContainer:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.betRow:hover {
  background-color: #eaeaea;
  transition: background-color 0.3s ease;
}

.categorySelect {
  margin-bottom: 20px;
  border-color: #007bff;
}

.betCardTitle {
  color: #333;
  font-weight: bold;
  font-size: 24px;
}

.claimButton, .placeBetButton {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.claimButton:hover, .placeBetButton:hover {
  background-color: #0056b3;
  color: #ffffff;
}

.countdownTimer {
  font-weight: bold;
  color: #007bff;
}
/* OpenBets.module.css */
.card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.betRow:hover {
  background-color: #f8f9fa; /* Light grey */
  cursor: pointer;
}

.countdownTimer {
  font-weight: bold;
  color: #28a745; /* Bootstrap success color */
}

.countdownTimer.nearingEnd {
  color: #dc3545; /* Bootstrap danger color */
}





.btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centers the buttons in the container */
}

.btn-group button {
  flex: 1 1 auto; /* Allows buttons to grow and shrink as needed */
  margin: 5px; /* Adds spacing between buttons */
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Keeps the content from spilling out */
  text-overflow: ellipsis; /* Adds ellipsis to overflowing text */
}


.btn-group button {
  margin: 5px;
  padding: 10px 20px; /* Ensures buttons are tap-friendly on mobile */
}

/* Responsive adjustments */
@media (max-width: 600px) { /* Adjust breakpoint as necessary */
  .btn-group {
    flex-direction: column; /* Stacks the buttons vertically on small screens */
  }

  .btn-group button {
    width: 100%; /* Full width buttons for easier tap targets */
  }
}
