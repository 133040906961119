.container .form-row {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Align items vertically */
  flex-wrap: wrap; /* Allows items to wrap */
  margin-bottom: 10px; /* Adds spacing between rows */
}

.container .form-row .field {
  flex-grow: 1; /* Allows fields to grow and fill space */
  max-width: 100%; /* Prevents fields from overflowing their container */
  padding: 0 15px; /* Adds padding for spacing */
}

@media (max-width: 768px) {
  .container .form-row .field {
    flex-basis: 100%; /* Makes each field take full width on smaller screens */
  }
}

.input-errors {
  position: static; /* Change position to static to keep within flow of document */
  color: red;
  font-size: 12px;
  padding-top: 4px; /* Adds a little space above the error message */
}


.container .form-row .field.full-width {
  flex: 0 0 100%; /* Takes full width on small screens */
}

.container .form-row .field.third-width {
  flex: 0 0 50%; /* Adjust the percentage slightly to include margins/padding */
  margin-right: 2%; /* Adds small space between fields */
}

@media (max-width: 768px) {
  .container .form-row .field.third-width {
    flex: 0 0 100%; /* Stack on smaller screens */
    margin-right: 0;
  }
}
.input-errors {
  font-size: 12px;
  position: absolute;
  bottom: -20px; /* Position below the input field */
  left: 5px;
  color: red;
}
.modal-body {
  padding: 20px;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}
.container .button-container button {
  min-width: 150px; /* Ensures buttons are not too small */
  padding: 10px 15px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .container .button-container {
    flex-direction: column; /* Stack buttons vertically on small screens */
  }
  .container .button-container button {
    width: auto;
    margin-bottom: 10px;
  }
}


















#payment-request-button {
  width: 300px;
  height: 40px;
  margin: 0 auto;
}

.hr {
  width: 100%; 
  height: 10px; 
  border-bottom: 1px solid black; 
  text-align: center;
  margin: 20px 0;
}

.hr span {
  font-size: 10px; 
  background-color: #333; 
  padding: 0 10px;
}